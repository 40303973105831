import React, { useState } from 'react'
import { CgMenu } from 'react-icons/cg'
import { BsSearch } from 'react-icons/bs'
import './style.css'
import { Link } from 'react-router-dom'
import { RiAccountCircleFill } from 'react-icons/ri'
import TabsBar from '../TabsBar'
import Menu from '../Menu'

function Navbar() {

  const [currentPage, setCurrentPage] = useState('/');

  const ActivatePage = () => {
    
  }

  return (
    <div className='navbar-wrapper'>
      <div className='navbar-container'>
        <div id='menu'><CgMenu size={16 * 1.5}/></div>
        {/* <Link id='login' to='/login'>Login</Link> */}
        <div id='waltrevino-logo' className='logo-menu'>
          {/* <Link id="nav-logo" to="./canvas">{"< W a l t r e v i n o />"}</Link> */}
          {/* <div id="nav-logo">
            <Link  to="./canvas">{"WRZE"}</Link>
          </div> */}
          <TabsBar />
        </div>
        <div id='login'><RiAccountCircleFill size={16*1.5} /></div>
      </div>
          <Menu />
    </div>
  )
}

export default Navbar;
import React, { Component } from 'react'
import './style.css'

class Footer extends Component{
  render(){
    return (
      <div className='footer-wrapper'>
        <div className='footer-container'>
          <div className='footer-content'>
               ....more & mas coming soon!
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
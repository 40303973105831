import React from 'react'
import styled from 'styled-components';
import TabsBar from '../TabsBar';

function Menu() {

  const Wrapper = styled.div`
   
   
  `

  const Container = styled.div`
    border: solid yellow 1px;
    display: flex;
    flex-direction: column;
  `

  return (
    <Wrapper>
      <Container>
      <TabsBar />
      </Container>
    </Wrapper>

  )
}

export default Menu;
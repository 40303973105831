import React from 'react'

function Mercado(){

  return (
    <div className='page-wrapper'>
      <div className='page-container'>
        <div className='page-content'>
          TEST
        </div>
      </div>
    </div>
  )
}

export default Mercado;